import * as React from 'react';
import { Box, Button, Card, Modal, Text } from '@hd/ui';
import { FormError, validateCCTVRecommendationProduct, validateNoteOrQuote } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import ProductForm from './ProductForm';
import useFirestoreProduct from '../../utils/useFirestoreProduct';
import { isPricingValid } from './util';

interface Props {
  onClose: () => void;
  visible: boolean;
}

export default function ProductAddModal(props: Props) {
  const { onClose, visible } = props;
  const { document, update, save, clear } = useFirestoreProduct();
  const [error, setError] = React.useState<FormError | null>(null);

  const handleSave = async () => {
    const error = validateCCTVRecommendationProduct(document);
    setError(error);

    if (error) {
      setError(error)
    } else if (!isPricingValid(document.pricing)) {
      setError({
        field: '',
        message: 'Contractor price exceed HD price'
      })
    } else {
      await save();
      clear();
      onClose();
    }
  };

  return (
    <Modal maxWidth="900px" onClose={onClose} visible={visible}>
      <Card backgroundColor="light" padding="x10">
        <Text size="x4">Add Product</Text>

        <ProductForm product={document} update={update}/>
        {error && <FormErrorMessage error={error} />}

        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x4"
          margin="x10"
        >
          <Button data-at-id="ModalCancel" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="blue"
            data-at-id="ModalConfirm"
            level="primary"
            onClick={handleSave}
            type="submit"
          >
            Create product
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
