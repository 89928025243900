import { Quote } from '@hd/types';
import firebase from 'firebase';
import useFirestoreDocument from './useFirestoreDocument';

export default function useFirestoreQuote(id?: string) {
  return useFirestoreDocument<Quote>('Quote', 'quotes', id, {
    price: 0,
    contractorPrice: 0,
    quoteText: '',
    createdAt: firebase.firestore.Timestamp.now(),
    status: 'AWAITING',
    hourlyRate: false,
    halfDayRate: false,
    dayRate: false,
    customerAddress1: '',
    customerAddress2: '',
    customerAddress3: '',
    customerContactName: '',
    customerNumber: '',
    quoteId: '',
    siteContactName: '',
    siteContactNumber: '',
    siteName: '',
    siteAddressLine1: '',
    siteAddressLine2: '',
    siteAddressLine3: ''
  });
}
