import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Grid,
  Image,
  LabelledList,
  Link,
  PageLoading,
  Text,
} from '@hd/ui';
import useFirestoreJobs from '../../utils/useFirestoreJobs';
import getAddress from '../../utils/getAddress';
import { Download, LinkAlt, Wallet } from '@styled-icons/boxicons-regular';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import useFirestoreJobReport from '../../utils/useFirestoreJobReport';
import { TypeFilter } from '../../utils/useFirestoreCollection';
import useFirestoreQuote from '../../utils/useFirestoreQuote';

export default function InvoiceContractor() {
  const { magicId } = useParams<{ magicId: string }>();
  const filters = React.useMemo(
    () => [['customerInvoiceId', '==', magicId]] as TypeFilter[],
    [magicId]
  );
  const ref = React.useRef<HTMLDivElement>(null);
  const { documents, ids, isFetching } = useFirestoreJobs({ filters });

  const document = documents[ids[0]];
  const vatType = document?.customer ? document.customer.vatType : 'VAT';
  const vatRate = vatType === 'VAT' && document ? document.vatRate : 0;
  const jobReport = useFirestoreJobReport(document ? document._id : undefined);

  const quote = useFirestoreQuote(
    document
      ? document.followUpQuote
        ? document.followUpQuote._id
        : undefined
      : undefined
  );

  const handlePay = () => {
    window.open(
      'https://happydrains.co.uk/payments/?jn=' + document?.jobNumber,
      '_HDPAY'
    );
  };

  const handlePrint = () => {
    window.print();
  };

  if (isFetching) {
    return <PageLoading />;
  }

  if (!document) {
    return (
      <Box padding="x10">
        <Card maxWidth="600px" maxWidthCentered>
          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Text align="middle" margin="x20" maxWidthCentered strong>
            Invoice not found.
          </Text>
          <Image src={require('@hd/ui/assets/footer.jpg')} />
        </Card>
      </Box>
    );
  }

  return (
    <Box padding="x10">
      <Card maxWidthCentered ref={ref} width="600px">
        <Image src={require('@hd/ui/assets/header-1.png')} />
        <Box padding="x6">
          <Text margin="x4" size="x4" strong>
            Invoice
          </Text>

          <Text size="x2">
            <LabelledList
              items={[
                ['Account Number', document?.customer?.sageReference],
                [
                  'Invoice Date',
                  document.invoiceCreated?.toDate().toDateString(),
                ],
                [
                  'Invoice Number',
                  (document?.customerInvoiceNumber || '').toString(),
                ],
                ['Job Number', document.jobNumber],
              ]}
              margin="x4"
            />

            <LabelledList
              items={[
                ['Purchase Order Number', document.purchaseOrderNumber],
                ['Customer Name', document.customer?.name],
                ['Customer Address', getAddress(document.customer)],
                ['Site Address', getAddress(document.customerSite)],
              ]}
              margin="x4"
            />

            <LabelledList
              items={[
                [
                  'Total Ex VAT',
                  toCurrencyFormat(document.priceCustomer.toString()),
                ],
                ['VAT type', vatType],
                ['VAT', vatRate + '%'],
                [
                  'Total Inc VAT',
                  toCurrencyFormat(
                    (document.priceCustomer * (1 + vatRate / 100)).toString()
                  ),
                ],
              ]}
              margin="x4"
            />

            <LabelledList
              items={[
                ['Company number', '08267396'],
                ['Bank Account Number', '15844749'],
                ['Sort Code', '01 01 23'],
                ['VAT Number', 'GB152 8560 06'],
                ['Registered office', '100 Church Street, Brighton, East Sussex, BN1 1UJ'],
                ['Trading address', 'Happy Drains Ltd, Unit 12A Chalex Industrial Estate, Manor Hall Road Southwick, East Sussex, BN42 4NH']

              ]}
              margin="x4"
            />

            {jobReport.document.unblockForm && document.isUnblock && (
              <Box >
                <LabelledList
                  items={[
                    [
                      'Successfully cleaned',
                      jobReport.document.unblockForm.cleanSuccess
                        ? 'Yes'
                        : 'No',
                    ],
                    ['Cause', jobReport.document.unblockForm.cause],
                    ['From', jobReport.document.unblockForm.fromCleared],
                    ['To', jobReport.document.unblockForm.toCleared],
                    [
                      'Equipment used',
                      jobReport.document.unblockForm.equpmentUsed,
                    ],
                  ]}
                  margin="x4"
                />
              </Box>
            )}
          </Text>

          <Box flex="horizontal" alignChildrenHorizontal="middle" padding='x3'>
            {document.isCCTV && document.status === 'PAID' && (
              <Box printHide>
                <Link to={`/quote/cu/${quote.document.magicId}`}>
                  <Button
                    elevation="x2"
                    color="blue"
                    onClick={}
                    level="primary"
                    flex="horizontal"
                    gap="x3"
                  >
                    Click here to view report/quote
                  </Button>
                </Link>
              </Box>
            )}
          </Box>

          <Box margin="x3">
            <Text strong>Notes from your engineer</Text>
            <Text
              backgroundColor="light-gray"
              borderColor="gray"
              borderRadius="x1"
              borderSize="x1"
              borderStyle="solid"
              margin="x1"
              padding="x3"
            >
              {jobReport.document?.publicNote || 'No note added'}
            </Text>
          </Box>

          {!document.isCCTV && (
            <Box margin="x3">
              <Text strong>Notes from Happy Drains</Text>
              <Text
                backgroundColor="light-gray"
                borderColor="gray"
                borderRadius="x1"
                borderSize="x1"
                borderStyle="solid"
                margin="x1"
                padding="x3"
              >
                {jobReport.document?.happyDrainsPrivateNote || 'No note added'}
              </Text>
            </Box>
          )}

          {document.potentialFurtherWork && !document.isCCTV && (
            <Box margin="x3">
              <Text strong>Follow up recommendations</Text>
              <Text
                backgroundColor="light-gray"
                borderColor="gray"
                borderRadius="x1"
                borderSize="x1"
                borderStyle="solid"
                margin="x1"
                padding="x3"
              >
                {quote.document.quoteText || 'This will be text from quote'}
                <Box printHide>
                  <Link to={`/quote/cu/${quote.document.magicId}`}>
                    <Button color="blue">
                      <LinkAlt size="1.25rem" />
                      Link to quote
                    </Button>
                  </Link>
                </Box>
              </Text>
            </Box>
          )}
          {document.isCCTV && document.status !== 'PAID' && (
            <Text strong>
              As per T&amp;C's the report is released upon payment!
            </Text>
          )}

          <Box margin="x6" style={{ breakBefore: 'page' }}>
            <Text margin="x1" strong>
              Images
            </Text>
            <Grid gap="x2" repeat="3" repeatWidth="1fr">
              {jobReport.document.files?.map(({ downloadUrl }) => (
                <Image key={downloadUrl} src={downloadUrl} />
              ))}
            </Grid>
          </Box>

          <Box flex="horizontal" gap="x3" margin="x6" printHide>
            <Button
              color="blue"
              level="primary"
              onClick={handlePay}
              size="large"
            >
              <Wallet size="20px" />
              <Text>Pay Here</Text>
            </Button>

            <Button
              color="blue"
              level="primary"
              onClick={handlePrint}
              size="large"
            >
              <Download size="20px" />
              <Text>Download or Print</Text>
            </Button>
          </Box>

          <Text>
            Please note that our payment terms are payment on completion.
          </Text>
          <Text>
            Invoice queries? Please{' '}
            <Link href="mailto:service@happydrains.co.uk">contact us</Link>.
          </Text>
        </Box>
        <Image src={require('@hd/ui/assets/footer.jpg')} />
        <Box padding="x5">
          <Text size="x1" align="middle">
            Happy Drains Ltd, Unit 12B, Chalex Industrial Estate, Manor Hall
            Road, Southwick, East Sussex, BN42 4NH
          </Text>
        </Box>
      </Card>
    </Box>
  );
}
