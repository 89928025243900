import * as React from 'react';
import QuoteForm from '../Quote/QuoteForm';
import { Quote, WebInquiry } from '@hd/types';
import firebase from 'firebase';
import useFirestoreDocument from '../../utils/useFirestoreDocument';
import { Button, Box } from '@hd/ui';
import { FormError, validateQuote } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import { useHistory } from 'react-router-dom';

type Props = {
  webInquiry: WebInquiry;
  onRemove: () => {};
  onComplete: () => Promise<void>;
};

export default function WebInquiryForm({
  webInquiry,
  onRemove,
  onComplete,
}: Props) {
  const [error, setError] = React.useState<FormError | null>(null);
  const { push } = useHistory();

  const {
    document: quote,
    update: updateQuote,
    save,
  } = useFirestoreDocument<Quote>('Quote', 'quotes', undefined, {
    siteAddressPostcode: webInquiry.postcode,
    siteContactNumber: webInquiry.phone,
    customerNumber: webInquiry.phone,
    customerPostcode: webInquiry.postcode,
    customerEmail: webInquiry.email,
    customerName: webInquiry.firstname + ' ' + webInquiry.surname,
    siteContactName: webInquiry.firstname + ' ' + webInquiry.surname,
    siteName: webInquiry.firstname + ' ' + webInquiry.surname,
    customerContactName: webInquiry.firstname + ' ' + webInquiry.surname,
    jobType: webInquiry.instantQuoteJobType?.name,
    isCCTV: webInquiry.instantQuoteJobType?.name,
    isCommercial: webInquiry.instantQuoteJobType?.name,
    isDomestic: webInquiry.instantQuoteJobType?.name,
    isRepair: webInquiry.instantQuoteJobType?.name,
    isUnblock: webInquiry.instantQuoteJobType?.name,
    quoteText: webInquiry.instantQuoteJobType?.salesInfo,
    price: webInquiry.instantQuoteJobType?.priceCustomer || 0,
    description: webInquiry.problem,
    createdAt: firebase.firestore.Timestamp.now(),
    status: 'AWAITING',
    hourlyRate: false,
    halfDayRate: false,
    dayRate: false,
  });

  const handleCreate = async () => {
    const error = validateQuote(quote);
    const price = parseFloat(quote.price.toString());

    if (isNaN(price)) {
      setError({
        field: '',
        message: 'Please ensure price is a number.',
      });
    } else {
      setError(error);
    }

    if (!error) {
      updateQuote({
        fromWebInquiry: webInquiry._id
      })

      await onComplete();
      const { _id } = await save();
      const sourceRef = firebase.firestore().collection('webInquiries').doc(webInquiry._id).collection('files');
      const destRef = firebase.firestore().collection('quotes').doc(_id).collection('files');

      await firebase.firestore().collection('webInquiries').doc(webInquiry._id).set({ toQuote: _id }, { merge: true}) 
      const documents = await sourceRef.get();
      if (!documents.empty) {
        let batch = firebase.firestore().batch();
        documents.forEach(doc => {
          const newDoc = destRef.doc(doc.id);
          batch.set(newDoc, doc.data());
        });
      
        batch.commit().then(() => {
          console.log('Subcollection copied successfully!');
        }).catch(error => {
          console.error('Error copying subcollection:', error);
        });
      }
      push(`/quotes/${_id}`);

    }
  };

  return (
    <>
      <QuoteForm quote={quote} update={updateQuote} />

      {error && <FormErrorMessage error={error} />}

      {webInquiry.status === 'MANUAL' && (
        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x2"
          margin="x4"
        >
          <Button
            color="blue"
            data-at-id="CreateJob"
            level="primary"
            onClick={handleCreate}
          >
            Create Quote
          </Button>
          <Button color="red" level="primary" onClick={onRemove}>
            Delete
          </Button>
        </Box>
      )}
      {webInquiry.status === 'AUTOMATIC' && (
        <Box
          alignChildrenHorizontal="end"
          flex="horizontal"
          gap="x2"
          margin="x4"
        >
          <Button color="red" level="primary" onClick={onRemove}>
            Delete
          </Button>
        </Box>
      )}
    </>
  );
}
